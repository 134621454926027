import React, { useState, useRef } from "react";
import Layout from "@components/Layout";
import BgImage from "gatsby-background-image";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { Row, Col } from "react-bootstrap";
import Video from "@components/Video";
import { measurementVideos } from "@utils/static-data";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import ArrowLeftCircle from "@assets/images/icon/arrow-circle-left-white.svg";

import css from "@assets/styles/scss/modules/ukuran.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StandarUkuran = () => {
  const img = useStaticQuery(graphql`
    query UkuranQuery {
      bgUkuranDesktop: file(
        relativePath: { eq: "bg/desktop/bg-hero-standar-ukuran.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1020, maxWidth: 2010, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bgUkuranMobile: file(
        relativePath: { eq: "bg/mobile/bg-standar-ukuran.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 680, maxWidth: 350, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      guideUkuran: file(
        relativePath: {
          eq: "pages/desktop/standar-ukuran/measurement-guide.png"
        }
      ) {
        childImageSharp {
          fluid(maxHeight: 502, maxWidth: 467, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tabelUkuran: file(
        relativePath: {
          eq: "pages/desktop/standar-ukuran/measurement-table.png"
        }
      ) {
        childImageSharp {
          fluid(maxHeight: 559, maxWidth: 563, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const sliderRef = useRef();
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [activeTabs, setActiveTabs] = useState({ index: 0 });

  const bg = [
    img.bgUkuranDesktop.childImageSharp.fluid,
    {
      ...img.bgUkuranMobile.childImageSharp.fluid,
      media: "(max-width: 1023.98px)",
    },
  ];

  const renderMesaurementDescList = () => {
    return measurementVideos.map((data, idx) => (
      <div
        key={idx}
        className={`${css.listItem} ${
          idx === activeTabs.index ? css.active : ``
        }`}
        onClick={() => setActiveTabs({ index: idx })}
      >
        <span>{idx + 1}.&nbsp;</span>
        <span dangerouslySetInnerHTML={{ __html: data.title }} />
      </div>
    ));
  };

  const renderMeasurementVideo = () => {
    let title = measurementVideos[activeTabs.index].title;
    let link = measurementVideos[activeTabs.index].link;
    let quality = measurementVideos[activeTabs.index].quality;
    return (
      <>
        <p>{title}</p>
        <div className={css.videoContainer}>
          <Video
            link={link}
            quality={quality}
            imgStyle={{
              borderRadius: 0,
            }}
          />
        </div>
      </>
    );
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    vertical: true,
    arrows: false,
    swipe: false,
    verticalSwiping: false,
    swipeToSlide: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: css.heroSlider,
  };

  return (
    <Layout noFooter>
      <BgImage fluid={bg} className={css.bgUkuran}>
        <Slider {...sliderSettings} ref={sliderRef}>
          <div className={css.ukuran}>
            <Row className={css.title}>
              <h1>
                Video Tutorial{isDesktop ? ` ` : <br />}
                <span style={{ color: "#f3611b" }}>Cara Mengukur</span>
              </h1>
            </Row>
            <Row className={css.content}>
              <Col md={6} className={css.video}>
                {renderMeasurementVideo()}
              </Col>
              <Col md={6} className={css.list}>
                {renderMesaurementDescList()}
              </Col>
            </Row>
            <Row className={css.arrowContainer}>
              <div className={css.nextSlide} onClick={nextSlide}>
                <img src={ArrowLeftCircle} />
                <span>Lihat Standar Ukuran Kami</span>
              </div>
            </Row>
          </div>
          <div className={css.ukuran}>
            <Row className={css.title}>
              <h1>
                Standar Ukuran{isDesktop ? ` ` : <br />}
                <span style={{ color: "#f3611b" }}>Jaket Kami</span>
              </h1>
            </Row>
            <Row className={css.content}>
              <Col md={6} className={css.guide}>
                <Img
                  fluid={img.guideUkuran.childImageSharp.fluid}
                  className="w-100 h-100"
                  imgStyle={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Col>
              <Col md={6} className={css.table}>
                <Img
                  fluid={img.tabelUkuran.childImageSharp.fluid}
                  className="w-100 h-100"
                  imgStyle={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Col>
            </Row>
            <Row className={css.arrowContainer}>
              <div className={css.nextSlide} onClick={prevSlide}>
                <img src={ArrowLeftCircle} className={css.up} />
                <span className={css.back}>Kembali Lihat Video Tutorial Cara Mengukur</span>
              </div>
            </Row>
          </div>
        </Slider>
      </BgImage>
    </Layout>
  );
};

export default StandarUkuran;
